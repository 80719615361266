import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/templates/single-page.js";
import { graphql } from 'gatsby';
export const data = graphql`
  query {
    tuition: file(relativePath: { eq: "tuition2021-22.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lunch: file(relativePath: { eq: "lunch2021-22.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    schedule: file(relativePath: { eq: "paymentschedule.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Img = makeShortcode("Img");
const layoutProps = {
  data,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Img fluid={props.data.tuition.childImageSharp.fluid} alt="A table containing the tuition for the current school year. For ages 2 to 3, the Co-op offers the Creative Play class from 9 AM to 12 PM, 2 through 5 days per week. Tuition ranges from $3,690 for 2 days to 6,710 for 5 days. For ages 3 to 5, the Co-op offers Discovery Pre-K class with morning sessions from 9 AM to 12:30 PM and afternoon sessions from 12 PM to 2:45 PM, with 3 through 10 sessions available per week. Tuition ranges from $5,800 for 3 sessions to $10,420 for 10 sessions, or 5 full days." style={{
      marginBottom: '1.75rem'
    }} mdxType="Img" />
    <p><strong parentName="p">{`Notes:`}</strong>{`  `}</p>
    <ul>
      <li parentName="ul">{`Creative Play students should be 2 years old by April 1st, 2021 (2 ½ years old by October 1st, 2021) and Discovery/ Pre-K students should be 3 years old by October 1st, 2021.  `}</li>
      <li parentName="ul">{`Our teachers recommend that 3 year olds attend 3 or more sessions, 4-5 year olds attend 4 or more sessions  `}</li>
      <li parentName="ul">{`Registration fees, deposits, and tuition are non-refundable.  `}</li>
      <li parentName="ul">{`A 10% discount will apply for families with more than one child enrolled at the Co-op. The discount will be applied to the lowest tuition class.  `}</li>
      <li parentName="ul">{`For children entering the Co-op after the first day of school, tuition will be prorated accordingly.  `}</li>
      <li parentName="ul">{`A late fee of $30 will be charged when tuition is received more than 10 days past the due date.`}</li>
      <li parentName="ul">{`Fees associated with field trips entail an extra nominal cost, and are payable before the trip. Note that field trips may alter the school-day schedule.  `}</li>
      <li parentName="ul">{`If you have any questions about your tuition, please `}<a parentName="li" {...{
          "href": "mailto:gary.sardo@watchungco-op.org"
        }}>{`email Treasurer, Gary Sardo`}</a>{`. If you have questions about registration please `}<a parentName="li" {...{
          "href": "mailto:mary.szubiak@watchungco-op.org"
        }}>{`email VP of Enrollment, Mary Szubiak`}</a>{` and `}<a parentName="li" {...{
          "href": "mailto:jenn.prochilo@watchungco-op.org"
        }}>{`Administrative Coordinator, Jenn Prochilo`}</a>{`.  `}</li>
    </ul>
    <hr></hr>
    <h2>{`Lunch at the Co-op`}</h2>
    <Img fluid={props.data.lunch.childImageSharp.fluid} alt="The total cost for school year 2021-22 to register Creative Play students for an optional half-hour lunch.  One day a week is $210, two days is $405, three days is $570, four days is $720, and five days is $855." style={{
      marginBottom: '1.75rem'
    }} mdxType="Img" />
    <p>{`Lunch is included in all of our DPK class sessions.  Children bring their lunches and are supervised by a teacher’s assistant. An optional half-hour lunch program, from 12 PM to 12:30 PM, is available to children in Creative Play. Any Creative families wishing to enroll in our Lunch Program please mark PM days on the registration form for the days you would like to add for lunch.`}</p>
    <hr></hr>
    <h2>{`Payment Schedule`}</h2>
    <Img fluid={props.data.schedule.childImageSharp.fluid} alt="The schedule of tuition payments for school year 2021-22.  A deposit is due upon enrollment, and after that, tuition payments are due on November 1, 2021; February 1, 2022; and April 1, 2022." style={{
      marginBottom: '1.75rem'
    }} mdxType="Img" />
    <p><strong parentName="p">{`Notes:`}</strong>{`  `}</p>
    <ul>
      <li parentName="ul">{`The nonrefundable enrollment deposit is approximately half of 1st tuition payment plus a $100 registration fee. Note that if you are enrolling a second child, the registration fee for that child is $50.  `}</li>
      <li parentName="ul">{`This amount is the balance of 1st tuition payment.`}</li>
    </ul>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      